<template>
  <div class="recruitmentjob">
        <van-sticky :offset-top="0">
          <div>
            <van-search
              v-model="listdatas.title"
              show-action
              label="职位"
              background="#0188fb"
              placeholder="请输入搜索关键词"
              @search="onSearchs"
            >
              <template #action>
                <div class="searchon" @click="onSearchs">搜索</div>
              </template>
            </van-search>
          </div>
        </van-sticky>
        <!-- 职位列表 -->
        <div>
          <div
            class="postlist"
            v-for="item in lists"
            :key="item"
            @click="todetail(item)"
          >
            <!-- <router-link :to="'/recruitment/detail/' + item.JId"> -->
            <div>
              <!-- 一 职位 薪资 -->
              <div class="listtitle">
                <span>{{ item.Title }}</span>
                <span style="color: red">{{ item.Salary }}</span>
              </div>
              <!-- 二 技能 学历 经验 -->
              <div class="listtwo">
                <span>工作经验:{{ item.WorkYear }}年</span>
                <span>学历要求:{{ item.EduName }}</span>
                <!-- <span>{{item.Skill}}</span> -->
              </div>
              <!-- 三 公司 人数 -->
              <div class="listthree">
                <span>企业:{{ item.EName }}</span>
                <span>
                  <van-icon name="friends" />
                  招聘人数:{{ item.RecruitNum }}人
                </span>
              </div>
              <!-- 四 招募人 地址 -->
              <div class="listfore">
                <span>
                  <van-icon name="manager" />
                  {{ item.StatusDesc }}
                </span>
                <span>
                  <van-icon name="map-marked" />
                  {{ item.WorkAddrName }}
                </span>
              </div>
            </div>
            <!-- </router-link> -->
          </div>
        </div>

    <router-link to="/list/firmlogin/jobList/position">
      <div class="nameResume">发布职位</div>
    </router-link>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { getaccToken, setaccToken } from "@/utils/auth";
import { RE_GetResumePage, RE_GetJobPage } from "@/api/REplat";
export default {
  data() {
    return {
      search: "", //搜索
      itemmark: "", //职位详情
      listdata: {
        accToken: getaccToken(), //token
        page: 1,
        limit: 100,
        name:"",
      },
      listdatas: {
        accToken: getaccToken(), //token
        title:'',
      },
      active: 0,
      list: [], //求职者列表
      lists: [], //职位列表
    };
  },
  created() {
    this.GetJobPage();
    this.GetJobPages();
    console.log(getaccToken());
  },
  methods: {
    onSearch() {this.GetJobPage()},
    onSearchs(){this.GetJobPages();},
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取求职者列表
    GetJobPage() {
      RE_GetResumePage(this.listdata).then((res) => {
        this.list = res.data.data;
      });
    },
    // 获取职业列表
    GetJobPages() {
      RE_GetJobPage(this.listdatas).then((res) => {
        this.lists = res.data.data;
      });
    },
    // 跳转职位详情
    todetail(row) {
      this.itemmark = JSON.stringify(row);
      this.$router.push({
        name: "detail",
        query: { JId: this.itemmark,state:1 },
      });
    },
    todetails(row){
      this.$router.push({
        name: "jobResume",
        query: { ReId: row },
      });
    },
  },
};
</script>
<style>
.recruitmentjob {
  background: #f2f8f9;
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.recruitmentjob .van-nav-bar__title {
  color: #fff;
}
.recruitmentjob .van-nav-bar__content {
  background: #0188fb;
}
.postlist {
  margin: 10px 0px;
  background: #fff;
}
.postlist .listtitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 700;
  color: #000;
}
.postlist .listtwo {
  padding: 0px 10px;
  font-size: 12px;
}
.postlist .listtwo span {
  padding: 6px;
  background: rgb(238, 237, 237);
  margin-right: 8px;
  color: #666;
}
.postlist .listthree {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 14px;
}
.postlist .listthree span {
  margin-right: 30px;
  color: #666;
}
.postlist .listfore {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0px 0px 15px 10px;
  font-size: 13px;
}
.postlist .listfore span {
  margin-right: 15px;
  color: #a3a7a8;
}
.van-search__action {
  margin-right: 10px !important;
  color: #fff;
}
.van-search__action:active {
  background: #0188fb;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.nameResumes {
  position: fixed;
  right: 100px;
  bottom: 20px;
  background: #0188fb;
  text-align: center;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.van-tabs--card>.van-tabs__wrap {
  height: 40px !important;
}
.van-tabs__nav--card {
  margin: 0px !important;
  height: 40px;
}
</style>